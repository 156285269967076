<template>
	<!-- <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10.5914 5.16368C13.6151 5.16368 16.0399 7.56852 16.0399 10.5674C16.0399 13.5663 13.6151 15.9711 10.5914 15.9711C7.56768 15.9711 5.14001 13.5691 5.14001 10.5674C5.14001 7.56565 7.56479 5.16368 10.5914 5.16368ZM10.4757 18.4907C6.01675 18.4247 2.46929 14.8089 2.53295 10.3866C2.5966 5.96434 6.24534 2.44604 10.7043 2.50918C13.2853 2.54648 15.5625 3.76899 16.9948 5.64866L19 4.12771C17.1047 1.67121 14.1273 0.0498141 10.739 0.00102861C4.89696 -0.0793239 0.0850235 4.55817 0.00111128 10.3522C-0.082801 16.1404 4.59603 20.9185 10.4381 20.9989C13.8293 21.0477 16.8472 19.5095 18.8119 17.1046L16.853 15.5234C15.3657 17.3658 13.0567 18.5223 10.4757 18.485V18.4907Z"
			fill="#cc0000"
		/>
	</svg> -->
	<svg width="44" height="48" viewBox="0 0 44 48" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M24.2595 42.2645C13.9335 42.1136 5.71835 33.8488 5.86577 23.7408C6.01319 13.6328 14.4629 5.59095 24.7888 5.73526C30.7659 5.82053 36.0395 8.61483 39.3563 12.9112L44 9.43476C39.611 3.81992 32.7159 0.113861 24.8692 0.00235111C11.3403 -0.181312 0.196897 10.4187 0.00257348 23.6621C-0.19175 36.8924 10.6434 47.8138 24.1723 47.9974C32.0257 48.1089 39.0146 44.5931 43.5644 39.0963L39.028 35.4821C35.5838 39.6932 30.2366 42.3367 24.2595 42.2514V42.2645Z" fill="black"/>
		<path d="M24.6243 11.8027C31.6266 11.8027 37.2419 17.2995 37.2419 24.1541C37.2419 31.0086 31.6266 36.5054 24.6243 36.5054C17.622 36.5054 12 31.0152 12 24.1541C12 17.2929 17.6153 11.8027 24.6243 11.8027Z" fill="#CC0000"/>
	</svg>

</template>
